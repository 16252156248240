.link__circle {
  width: 5vw;
  height: 5vw;
  max-width: 6em;
  max-height: 6em;
  min-width: 3em;
  min-height: 3em;
  margin: 0.5em;
  display: grid;
  grid-template-rows: calc(max(4em, 5vw)) 1vw;
  grid-template-columns: calc(max(4em, 5vw));

  &__text {
    grid-row: 2;
    justify-self: center;
    transform: translateY(20%);

    &__content {
      font-size: calc(max(2vw, 1em));
      color: rgba(0, 0, 0, 0.4);
    }
  }
}