.circle {
  background-color: white; //var(--primary-color);
  color: #999;
  border-radius: 50%;
  border:0.2em solid rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: 0.4s ease;
}
