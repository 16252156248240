.canvas {
  background: black;
  scrollbar-width: none;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.testest {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  color: white;
  z-index: 99;
  position: fixed;
  width: 100%;
}

.titleText {
  font-family: var(--font-heading);
  color: white;
  position: absolute;
  opacity: 0;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(max(6vw, 3em));
  z-index: 2;
  white-space: nowrap;
}
