.container {
  width: 100vw;
  height: 300vh;
  &__content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    font-size: calc(clamp(0.8rem, 7.3vw, 0.1rem));

    &__background{
      position: absolute;
      z-index: -1;
      height: 150%;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%);
    }

    &__text {
      //background: red;
      width: 9em;
      white-space: pre-line;
      font-family: var(--font-title);
      font-size: calc(clamp(2.7em, 7.3vw, 6em));
      padding-left: 1em;
      //padding-right: 1em;

      &--upper {
        position: relative;
        z-index: 4;
        margin-bottom: -0.65em;
      }

      &--subtext {
        position: relative;
        font-size: calc(clamp(1rem, 2vw, 2rem));
        z-index: 4;
      }
    }

    &__programms {
      opacity: 0;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__cards {
        position: relative;
        z-index: 0;
        max-width: 48em;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-evenly;
      }
    }
  }
}
