.container {
  width: 100vw;

  &__grid {
    display: grid;
    grid-template-columns: 0 0 auto;
    color: black;
    height: auto;

    @media (min-width: 1000px) {
      grid-template-columns: 18% 3px auto;
    }

    &__element {
      padding: 1.5em;
      padding-top: 1em;
      padding-bottom: 3em;
      width: auto;
      grid-column: 3;
      position: relative;
      

      &__title {
        width: fit-content;
        position: relative;
      }

      &__box {
        width: fit-content;
        position: relative;
        padding: 1.5em;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        transform: translateY(-0.5em);
        margin-right: auto;
      }
    }
  }
  h1 {
    width: fit-content;
    position: relative;
    z-index: 2;
    font-style: italic;
  }
  
  h2 {
    font-style: italic;
  }
  
  h1, h2 {
    font-family: var(--font-title);
  }
  
  p {
    padding-left: 1em;
    font-family: var(--font-base)
  }
}

