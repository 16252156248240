.container {
  &__top {
    &__block {
      margin-left: 10%;
      margin-top: 5%;

      &__title {
        font-family: var(--font-heading);
        font-size: 3em;
        color: var(--darkestBlue-color);
      }

      &__topic {
        font-family: var(--font-heading);
        font-size: 1.5em;
        color: var(--lightGray-color);
        text-transform: uppercase;
      }

      &__subtext {
        font-family: var(--font-base);
        font-size: 1.1em;
        color: var(--darkestBlue-color);
      }
    }
  }

  &__content {
    background: #f5f6f7;
    margin-top: 3%;
    padding: 2% 15% 3% 10%;

    &__style {
      color: var(--darkestBlue-color);
      font-family: var(--font-base);
      max-width: 800px;

      li {
        list-style-type: disc;
        margin-left: 1.5em;
      }

      p,
      ul {
        margin-bottom: 1em;
        opacity: 0.8;
      }

      h1,
      h2,
      h3 {
        margin-bottom: 0.2em;
      }

      em {
        font-style: normal;
        font-weight: bold;
      }
    }
  }
}

.emphasis {
  font-style: normal;
  font-weight: bold;
}
