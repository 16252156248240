.Landing {
  font-family: var(--font-base);
  scrollbar-width: auto;
  overflow: hidden;
}

.default__div {
  width: 100%;
  height: 100%;
}

.dev__details {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
}
