.container {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-color: black;

  &__content {
    position: relative;
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}