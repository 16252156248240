.card {
  position: relative;
  width: 10em;
  height: 10em;
  padding: 0.5em;
  -webkit-tap-highlight-color: transparent;
  

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    pointer-events: none;

    &__content {
      cursor: pointer;
      pointer-events: auto;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 20%;
      transition: opacity 0.5s;
      background: #f5f5f5;
      background: -webkit-linear-gradient(145deg, #f5f5f5 0%, #fafafa 100%);
      background: linear-gradient(145deg, #f5f5f5 0%, #fafafa 100%);
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__style {
      width: 50%;
      height: 50%;
    }
  }
}
