.container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  
 

  &__title {
    scale: 1;
    align-self: center;
    margin-left: 50%;
    transform: translateX(-50%);
    font-family: var(--font-heading);
    font-size: 10vw;
    font-weight: 700;
    letter-spacing: -0.05em;
    white-space: nowrap;
    display:inline;
    color: white;

    &__box{
      overflow: hidden;
      font-size: 10vw;
      width: 1em;
      height: 0.35em; 
      display: inline-block;
      margin: 0.1em 0.1em 0 0.1em;

      &--video {
        font-size: 10vw;
        width: 5em;
        transform: translate(-50%, -50%);
      }
    }
  }
}