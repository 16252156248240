/* From uiverse.io */
.hoverbutton {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  height: auto;

  &:hover {
    .hoverbutton__circle {
      width: 125%;
    }

    .hoverbutton__icon {
      background: #282936;
      transform: translate(0.2em, 0);
    }

    .hoverbutton__text {
      color: #282936;
    }
  }

  &__circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.075, 1);
    position: absolute;
    display: inline-block;
    margin: 0;
    width: 1em;
    height: 1em;
    background: #fff;
    border-radius: 1em;
    transform: translate(0, 0.2em);
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #282936;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.075, 1);
    left: 0.1em;
    width: 0.5em;
    height: 0.11em;
    background: none;

    &::before {
      position: absolute;
      content: '';
      top: -0.12em;
      right: 0em;
      width: 0.25em;
      height: 0.25em;
      border-top: 0.1em solid #282936;
      border-right: 0.1em solid #282936;
      transform: rotate(45deg);
    }
  }

  &__text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.075, 1);
    position: relative;
    top: 0;
    left: 1.2em;
    right: 0;
    bottom: 0;
    margin: 0 0 0 0.2em;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
  }
}