.container {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &__content {
    height: 100vh;
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 0, 0),
      rgba(0, 0, 0, 0.9)
    );
    position: relative;
    opacity: 0;

    &__text {
      color: white;
      text-align: right;
      font-family: var(--font-title);
      position: relative;
      top: 0.6em;
      padding-right: 0.3em;
      font-size: calc(max(3.3em, 7vw));

      &--detailed {
        position: absolute;
        font-size: calc(min(0.4em));
        width: 60%;
        right: 1em;
        padding-top: 1.5em;
        font-size: calc(min(0.3em, 2rem));
      }
    }
  }

  &__image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media (min-aspect-ratio: 1/1) {
      object-fit: cover;
      object-position: 0% 50%;
    }

    @media (max-aspect-ratio: 1/1) {
      object-position: 18% 50%;
      //height: 100%;
      //left: -25%;
    }
  }
}
