.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;
  &__content {
    &__text {
      position: absolute;
      font-family: var(--font-game);
      font-size: calc(max(3.7em, 7vw));
      color: white;
      top: 10vh;
      right: 5vw;
      padding-left: 1.5em;
      text-align: right;

      &--wrapper {
        position: relative;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
      }
    }
  }

  &__images {
    &--fomka {
      position: absolute;
      width: calc(max(3.7em, 6vw));
      bottom: 10%;
      right: 20%;
    }

    &--player {
      position: absolute;
      width: calc(max(3.7em, 5vw));
      top: 18em;
      left: 7em;
    }
  }
}
