.section {
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: hsla(166, 100%, 50%, 1);
  background-image: radial-gradient(
      at 80% 0%,
      hsla(189, 100%, 53%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 0% 52%, hsla(210, 100%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 71% 70%, hsla(184, 91%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(183, 59%, 48%, 1) 0px, transparent 50%),
    radial-gradient(at 69% 93%, hsla(216, 100%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(201, 100%, 66%, 1) 0px, transparent 50%);
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;

  @media (min-width: 768px) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @media (min-width: 1024px) {
    padding-left: 128px;
    padding-right: 128px;
  }

  &__heading {
    h1 {
      font-family: var(--font-heading);
      color: var(--darkBlue-color);
      font-size: 3em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 16px;
    }

    p {
      font-family: var(--font-heading);
      color: var(--darkBlue-color);
      font-size: 18px;
      line-height: 1.5;

      text-align: center;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    background-color: #fff;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

    em {
      color: #d90429;
      padding-left: 1em;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &__group {
      font-size: 1.1em;
      font-family: var(--font-base);

      &--big {
        font-size: 1.1em;
        font-family: var(--font-base);
        grid-column: 1 / -1;
        resize: none;
        min-height: 100px;
      }

      &--label {
        display: block;
        margin-bottom: 5px;
        color: #888;
      }

      &--input {
        font-size: 1em;
        appearance: none;
        outline: none;
        border: none;

        display: block;
        width: 100%;

        border-radius: 8px;
        padding: 12px 16px;
        background-color: #f3f3f3;
        transition: 0.4s;

        &:focus {
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
          background-color: #fff;
        }

        &::placeholder {
          opacity: 0.3;
        }
      }

      &--textarea {
        font-size: 1em;
        font-family: var(--font-base);
        resize: none;
        min-height: 100px;
      }
    }

    &__submit {
      grid-column: 1 / -1;
      text-align: right;

      input[type='submit'] {
        appearance: none;
        border: none;
        outline: none;
        background: none;

        padding: 12px 16px;
        background-color: var(--secondaryBlue-color);
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        transition: 0.4s;

        &:hover {
          background-color: var(--darkBlue-color);
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1em;
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }

  &:target {
    visibility: visible;
    opacity: 1;
  }

  &__popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 90%;
    max-width: 20em;
    position: relative;
    transition: all 5s ease-in-out;
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
    position: absolute;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    max-height: 30%;
    
    overflow: auto;

    &--close {
      position: absolute;
      top: 20px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #333;

      &:hover {
        color: #06d85f;
        cursor: pointer;
      }
    }

    &--content {
      font-size: 0.8em;
      max-height: 30%;
      min-height: 40px;
    overflow: auto;
    }
  }
}

@media screen and (max-width: 700px) {
  .overlay__popup {
    width: 70%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;

  &:before {
    content: '';
    width: 48px;
    height: 5px;
    background: #06d85f50;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.5s linear infinite;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #06d85f;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    animation: jump 0.5s linear infinite;
  }
}


@keyframes jump {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {

  0%,
    100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}