.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;

    &__more{
      position: absolute;
      font-size: calc(clamp(0.5em, 1vw, 1em));
      bottom: 10em;
      left: 5em;
      &--icon {
        position: relative;
        top: 0.12em;
      }
    }
  }

  &__heading {
    color: white;
    font-size: calc(clamp(5em, 10vw, 10em));
    z-index: 1;
    position: absolute;
    top: 25%;
    left: 10%;
    animation-timing-function: ease-out;
    transform: translateX(100%);
    opacity: 0;
        /* Maximum aspect ratio */
        @media (min-aspect-ratio: 4/2) {
          top: 130%;
        }
        /* Maximum aspect ratio */
        @media (max-aspect-ratio: 4/2) and (min-aspect-ratio: 3/2) {
          top: 100%;
        }
        /* Maximum aspect ratio */
        @media (max-aspect-ratio: 3/2) and (min-aspect-ratio: 1/1) {
          top: 70%;
        }
        @media (max-aspect-ratio: 1/1) and (min-aspect-ratio: 2/3) {
          top: 40%;
        }
        @media (max-aspect-ratio: 2/3) {
          left: 20%;
        }
  }

  &__images {
    height: 100vh;
    position: relative;
    z-index: 0;
    

    /* Maximum aspect ratio */
    @media (min-aspect-ratio: 4/2) {
      width: 100%;
      transform: scale(100%) translate(0, -100%);
    }

    /* Maximum aspect ratio */
    @media (max-aspect-ratio: 4/2) and (min-aspect-ratio: 3/2) {
      width: 100%;
      transform: scale(100%) translate(0, -70%);
    }
    /* Maximum aspect ratio */
    @media (max-aspect-ratio: 3/2) and (min-aspect-ratio: 1/1) {
      width: 100%;
      transform: scale(100%) translate(0, -50%);
    }

    @media (max-aspect-ratio: 2/3) {
      transform: translate(-5%, 0);
    }

    &__background {
      width: 100vw;
      @media (max-aspect-ratio: 2/3) {
        width: auto;
        height: 100%;
      }
    }

    &__foreground {
      position: absolute;
      width: 100vw;
      @media (max-aspect-ratio: 2/3) {
        width: auto;
        height: 100%;
      }
      left: 0;
      top: 0;
      z-index: 3;
    }
  }
}
