.container {
  overflow-y: hidden;
}

.head__container {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 0;
  align-content: center;
  justify-content: center;

  &__image--iPadTable {
    overflow: hidden;
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-aspect-ratio: 1/1) and (min-aspect-ratio: 2/3) {
      transform: rotate(90deg) translate(-35%, 70%);
      width: 70%;
    }

    @media (max-aspect-ratio: 2/3) {
      transform: rotate(90deg) translate(-35%, 70%);
      width: 120%;
    }
  }

  &__image--projects {
    width: 40%;
    align-self: center;
    justify-self: center;
    overflow: hidden;

    @media (max-aspect-ratio: 2/3) {
      width: 75%;
    }
  }
}

.head {

}

.head__test {
  width: 100vw;
  position: absolute;
  align-self: center;
  justify-self: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.clipTest {
  font-family: var(--font-title);
  font-size: 150px;
  background: #76fdff;
  background: -webkit-linear-gradient(132deg, #76fdff 0%, #002eec 100%);
  background: linear-gradient(132deg, #76fdff 0%, #002eec 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}