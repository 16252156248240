$small-screen: 600px;

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  overflow: visible;
}

.card {
  width: auto;
  height: auto;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  margin: 5vh 5% 5% 5%;

  &__top {
    width: auto;
    height: fit-content;
    position: relative;

    &__title {
      position: relative;
      max-width: 80%;
      z-index: 2;
      top: 30px;
      left: 30px;

      @media only screen and (max-width: $small-screen) {
        max-width: 100%;
      }
    }

    &__icon {
      position: absolute;
      z-index: 2;
      top: 40px;
      right: 30px;
      width: 5em;

      @media only screen and (max-width: $small-screen) {
        width: 32px;
        height: auto;
        left: 30px;
        top: 20px;
      }
    }

    &__category {
      color: gray;
      font-size: 14px;
      text-transform: uppercase;

      @media only screen and (max-width: $small-screen) {
        margin-left: 35px;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    //background: linear-gradient(145deg, #d4d4d4, #fbfbfb);
          background: #f5f5f5;
      background: -webkit-linear-gradient(145deg, #f5f5f5 0%, #fafafa 100%);
      background: linear-gradient(145deg, #f5f5f5 0%, #fafafa 100%);
    margin: 0 auto;
    height: auto;
    max-width: 700px;
    overflow: hidden;
    z-index: 1;

    &__container {
      &__text {
        max-height: 65vh;
        overflow-y: auto;
        position: relative;
        margin-top: 40px;
        padding: 0 35px 35px 35px;
        max-width: 700px;
        width: 90vw;
        z-index: 2;
      }
    }
  }
}
