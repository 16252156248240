.container {
  width: 100vw;
  background: black;

  &__content {
    width: 100%;
    height: 100%;

    &__competition {
      position: relative;
      &--title {
        position: absolute;
        width: 100vw;
        font-size: calc(max(1.8em,2vw));
        font-family: var(--font-heading);
        white-space: nowrap;
        overflow: visible;
        opacity: 0;
        color: #ccc;
        display: flex;
        align-items: top;
        justify-content: center;
      }

      &--elements {
        width: 100%;
        height: auto;
        display: flex;
        align-items: top;
        justify-content: space-evenly;
        margin-bottom: 20vh;

        @media (max-aspect-ratio: 1/1) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &--experience {
      width: 100%;
      height: auto;
      display: flex;
      align-items: top;
      justify-content: space-evenly;
    }

    &--yellow {
      color: #ffcc12;
      font-style: normal;
      font-weight: bold;
    }

    &--orange {
      color: #ff822f;
      font-style: normal;
      font-weight: bold;
    }

    &--green {
      color: #38b000;
      font-style: normal;
      font-weight: bold;
    }

    &--spacer {
      height: 70vh;
    }
  }
}
