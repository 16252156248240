.container {
  
  &__text {
  }

  &__button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-weight: bold;
    font-style: italic;
  }
}
