.container {
  max-width: 35em;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  //flex: 1 1 100%;
  //padding: 45px 25px;
  /*&::before {
    content: "";
    width: calc(var(--s)/2 + var(--m));
    float: left;
    height: 100%;
    shape-outside: repeating-linear-gradient(     
                    transparent 0 calc(var(--r) - 3px),      
                    #fff        0 var(--r));
  }*/
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}


/*ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}*/