.container {
  overflow: hidden;
  background-color: #f5f5f5;

  &__title {
    position: relative;
    display: flex;
    align-self: center;
    justify-self: center;
    width: calc(min(90vw, 1000px));
  }

  &__timeline {
    width: 100%;
    overflow: hidden;
  }

  &__wave{
    width: 100%;
  }
}