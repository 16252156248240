.container {
  width: calc(max(min(600px, 40%), min(350px, 100vw)));
  height: 150vh;

  &__content {
    width: 100%;
    position: relative;

    &__image {
      width: 100%;
      opacity: 0;
    }

    &__text {
      opacity: 0;
      color: white;
      font-size: calc(max(min(2.5vw, 2em), 1.2em));
      font-family: var(--font-title);
    }
  }
}
