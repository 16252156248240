.background {
  width: 100vw;
  height: 100vh;
  background-color: hsla(166, 100%, 50%, 1);
  background-image: radial-gradient(
      at 80% 0%,
      hsla(189, 100%, 53%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 0% 52%, hsla(210, 100%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 71% 70%, hsla(184, 91%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(183, 59%, 48%, 1) 0px, transparent 50%),
    radial-gradient(at 69% 93%, hsla(216, 100%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(201, 100%, 66%, 1) 0px, transparent 50%);
  display: flex;
  justify-content: center;
}

.link__container {
  position: absolute;
  bottom: 12%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  column-gap: 5vw;
}

.link__circle__icon {
  width: 60%;
  height: 60%;
}

.alLogo {
  overflow: hidden;
  width: 30%;
  min-width: calc(min(25em, 100vw));
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll__index {
  position: absolute;
  bottom: 3%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  column-gap: 5vw;
}
