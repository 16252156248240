.container {
  height: 200vh;
  width: 100%;

  &__content {
    height: 100vh;
    width: 100%;

    &__text {
      font-family: var(--font-title);
      position: relative;
      top: 1em;
      padding-left: 0.2em;
      font-size: calc(max(3.7em, 7vw));

      &--detailed {
        font-size: calc(min(2rem, 0.3em));
        width: 50%;

        @media (max-aspect-ratio: 2/3) {
          width: 100%;
        }
      }

      &--gradient {
        transform: translateY(-30%);
      }
    }

    &__image {
      position: absolute;
      height: auto;
      width: 60%;
      bottom: 0;
      right: 0;
      opacity: 0;

      @media (min-aspect-ratio: 1/1) {
        width: auto;
        height: 100vh;
      }

      @media (max-aspect-ratio: 2/3) {
        width: 100%;
      }
    }
  }
}
