.timeline {
  display: grid;
  grid-template-columns: 10% 3px auto;
  color: black;
  min-height: 500px;
  height: auto;

  @media (min-width: 1000px) {
    grid-template-columns: 18% 3px auto;
  }

  &__content {
    width: fit-content;
    &__text {
      padding: 1.5em;
      padding-left: 2em;
      padding-top: 1em;
      width: auto;
    }

    &__date {
    }
  }
}

.timeline__component {
  position: relative;

  &--bottom {
    margin-top: auto;
    margin-bottom: 0;
  }

  &--bg {
    padding: 1.5em;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transform: translateY(-1em);
    margin-right: auto;

    &--bottom {
      transform: translateY(0%);
    }
  }
}

.timeline__date {
  transform: translateY(-20%);
  position: relative;
  text-align: left;
  padding-left: 2em;
  padding-top: 0.5em;

  &--begin {
    padding-bottom: 0.15em;
  }

  &--end {
    margin-left: 1em;
  }

  &--stillActive {
    padding: 0.1em;
    background: #38b000;
    color: white;
    border-radius: 0.25em;
  }
}

.timeline__middle {
  position: relative;
  background: gray;
}

.timeline__end {
  position: relative;
}

.timeline__point {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2em;
  height: 2em;

  &--bottom {
    top: initial;
    bottom: 0;
  }
}

.timeline__progress {
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #023e8a;
  transition: 0.4s ease;
}

.timeline__title {
  margin: 0;
  font-size: 1.15 em;
  font-weight: bold;
}

.timeline__paragraph {
  line-height: 1.5;
}
