.container {
  display: flex;
  width: 100vw;
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;

  &__inner {
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.honeycomb {
  //flex: 1;
  position: relative;
  width: 30em;

  &__hint {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
    padding-top: 2em;
    opacity: 0.7;
  }
}

.rota {
  width: calc(max(40vw, 20em));
  position: relative;
}
