.container {
  font-size: 60px;
  position: relative;
  display: inline-block;
  z-index: 3;
  overflow: visible;
  white-space: nowrap;

  &__mark {
    position: absolute;
    width: 110%;
    height: 3em;
    left: -5%;
    z-index: -1;
    stroke-linecap: round;
    stroke-linejoin: round;

    &__fill {
      stroke: #1ad928;
      stroke-width: 300;
      fill: none;
      clip-path: url(#mark-clip-path);
    }
  }
}

