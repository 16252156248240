.icon {
  width: 100%;
  height: auto;
  color: -webkit-linear-gradient(58deg, #48cae4 0%, #bc48ff 100%);
  color: linear-gradient(58deg, #48cae4 0%, #bc48ff 100%);
  z-index: 2;

  &--js {
    color: #FFDF00
  }

  &--node {
    color: #8ABC3C;
  }

  &--react {
    color: #5CCFEE;
  }

  &--sass {
    color: #C26191;
  }

  &--html {
    color: #D84924;
  }

  &--sql {
    color: #006F88;
  }

  &--git {
    color: #E44C30;
  }
}

.container {
  h1,
  h2,
  h3 {
    font-family: var(--font-heading);
    margin-top: 2em;
  }

  h1 {
    font-size: 1.7em;
  }

  h2 {
    font-size: 1.3em;
  }

  p {
    font-family: var(--font-base);
    font-size: 1.2em;
  }

  em {
    font-style: normal;
    font-weight: bold;
  }

  &__icons {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2em;

    &--icon {
      width: 4em;
      height: 4em;
      border-radius: 50%;
      margin: calc(min(0.3vw, 0.4em));

      display: flex;
      align-items: center;
      justify-content: center;
      
      position: relative;
      overflow: hidden;
      border: 0.2em solid rgba(0, 0, 0, 0.05);

      -webkit-transition: all .5s;
      transition: all .5s;
      
      &:hover {
        transform: scale(0.9);
      }
    }
  }
}