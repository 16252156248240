.typewriter__container {
  align-self: center;
}

.typewriter__text {
  font-size: 10em;
  font-size: clamp(16px, 30vw, 10em);
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.typewriter__blinker{
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50%{
    opacity: 0;
  }
}
