.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(min(70vw, 70vh));
  //background-color: f5f5f5;
  touch-action: none;
  //background-color: hsla(166, 100%, 50%, 1);
  /*background-image: radial-gradient(
      at 80% 0%,
      hsla(189, 100%, 53%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 0% 52%, hsla(210, 100%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 71% 70%, hsla(184, 91%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(183, 59%, 48%, 1) 0px, transparent 50%),
    radial-gradient(at 69% 93%, hsla(216, 100%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(201, 100%, 66%, 1) 0px, transparent 50%);*/
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  &::selection {
    color: var(--selection-color);
    background: var(--selection-background-color);
  }
}

.cursor {
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}