.container {
  padding: 0.5em;
  pointer-events: none;
  
  &__card {
    position: relative;
    z-index: 1;
    width: calc(clamp(7em, 7.3vw, 10em));
    border-radius: 2em;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
      -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;

    &__image {
      position: relative;
      width: inherit;
      height: inherit;
      border-radius: 2em;
      object-fit: cover;
    }

    &__title {
      position: relative;
      z-index: 1;
      text-align: center;
      border-radius: 0px 0px 40px 40px;
      font-family: sans-serif;
      font-weight: bold;
      font-size: 1.5em;
      margin-top: -1.5em;
      height: 1em;
    }
    @media not all and (hover: none) {
      pointer-events: all;
      &:hover {
        
        transform: scale(0.9, 0.9);
        box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
          -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
      }
    }
  }

  &__subtext {
    position: relative;
    margin-top: 0.3em;
    margin-left: 0.5em;
    transform: translateY(-50%);
    font-size: 1.7em;
    font-weight:bold;
    z-index: 0;
    opacity: 0;
  }
}

@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}
