.text {
  background: #48cae4;
  background: -webkit-linear-gradient(58deg, #48cae4 0%, #bc48ff 100%);
  background: linear-gradient(58deg, #48cae4 0%, #bc48ff 100%);
  background-size: 150% 150%;
  background-position: 0% 50%;
  //animation: gradient 5s ease infinite;
  color: transparent;
}

/*@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
*/