.container {
  position: relative;
  height: 100%;
  width: 100%;
}

.background {
  height: 100%;
  width: 100%;
  z-index: -1
}

.animatedBackground {
  position: absolute;
  background: red;
  border-radius: 2vw;
  top: calc(max(1em, 1vw));
  left: calc(max(1em, 1vw));
  right: calc(max(1em, 1vw));
  bottom: 0px;
  border-radius: 2vw;
}
