.container {
  width: 100%;
  font-family: var(--font-base);

  &__socials{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 0 2em 0;

    &__items {
      margin: 0 1em 0 1em;
      background-color: white; //var(--primary-color);
      border-radius: 50%;
      border: 1px solid #ccc;
      height: 4em;
      width: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      transition: 0.4s ease;


      &--icons {
        width: 70%;
        height: 70%;
        opacity: 0.7;
        color: black;
      }

      &:hover {
        transform: scale(0.9);
        
        .container__socials__items--icons {
          opacity: 1;
        }
      }
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }

  &__pages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2em 0 2em 0;

    &__items {
      margin: 0 2vw 0 2vw;
      font-family: var(--font-title);
      font-size: 1.2em;
      opacity: 0.8;
      transition: 0.5s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 0 2em 0;
    opacity: 0.6;
  }
}