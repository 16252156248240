.card {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  border-radius: 2vw;
  z-index: 1;

  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0;
  }
 }
 
 .tools {
  display: flex;
  align-items: center;
  padding: 9px;
 }
 
 .circle {
  padding: 0 4px;
 }
 
 .box {
  display: inline-block;
  align-items: center;
  width: 1vw;
  max-width: 0.5em;
  max-height: 0.5em;
  height: 1vw;
  padding: 1vw;
  border-radius: 50%;
 }
 
 .red {
  background-color: #ff605c;
 }
 
 .yellow {
  background-color: #ffbd44;
 }
 
 .green {
  background-color: #00ca4e;
 }
 