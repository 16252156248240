.container {
  width: 40vw;
  min-width: 35rem;
  color: #1d1d1f;
  font-size:  calc(max(4rem, 7.3vw));
  line-height: 1.06em;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -0.02em;
  font-weight: bolder;
}

.mask {
  height: 1.2em;
  overflow: hidden;
  position: relative;
}

.mask span {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  transform: translateY(1.2em);
  padding-bottom: 6px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  white-space: nowrap;
}

.mask span[data-show] {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
  opacity: 1;
}

.mask span[data-up] {
  transform: translateY(-1.2em);
  transition: transform 0.5s ease-in-out;
}

.mask span:nth-child(1) {
  background-image: linear-gradient(45deg, #247ff6, 50%, #21c0ff);
}
.mask span:nth-child(2) {
  background-image: linear-gradient(45deg, #19ac4d, 50%, #79ff5b);
}
.mask span:nth-child(3) {
  background-image: linear-gradient(45deg, #4e2cad, 50%, #e842e2);
}
.mask span:nth-child(4) {
  background-image: linear-gradient(45deg, #e12323, 50%, #ff53af);
}