@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto:wght@400;500&family=Source+Code+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

:root {
  --font-base: 'Roboto', sans-serif;
  --font-code: 'Source Code Pro', monospace;
  --font-title: 'Poppins', sans-serif;
  --font-heading: 'Montserrat', sans-serif;
  --font-game: 'Press Start 2P', cursive;

  --primary-color: #f8f9fa;
  --black-color: #030303;
  --lightGray-color: #495057;
  --gray-color: #343a40;
  --darkGray-color: #212529;
  --white-color: #ffffff;

  --darkestBlue-color: #03045e;
  --darkBlue-color: #023e8a;
  --secondaryBlue-color: #00b4d8;
  --cyanBlue-color: #48cae4;
  --lightBlue-color: #90e0ef;
  --lightestBlue-color: #caf0f8;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.disable-scroll {
  overflow-y: hidden;
}

