.icon {
  width: 100%;
  height: auto;
}

.container {
  h1,
  h2,
  h3 {
    font-family: var(--font-heading);
    margin-top: 2em;
  }

  h1 {
    font-size: 1.7em;
  }

  h2 {
    margin-top: 1.2em;
    font-size: 1.3em;
  }

  p {
    font-family: var(--font-base);
    font-size: 1.2em;
  }

  em {
    font-style: normal;
    font-weight: bold;
  }

  li {
    list-style-type: disc;
    margin-left: 1.5em;
  }

  a {
    $link: #ec407a;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: $link;
    z-index: 6;
    &::after {
      content: '';
      background: rgba($link, 0.25);
      position: absolute;
      left: 12px;
      bottom: -6px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      z-index: -1;
      transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
    }
    &:hover:after {
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 100%;
    }
  }
}
