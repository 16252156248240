.icon {
  width: 100%;
  height: auto;
  color: #000;
  //stroke: black;
  //stroke-width: 1px;

  &--android {
    color: #91c200;
    height: 7em;
    margin-top: 2em;
    margin-right: 1em;
    width: auto;
  }
}
.android {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container {
  h1,
  h2,
  h3 {
    font-family: var(--font-heading);
    margin-top: 2em;
  }

  h1 {
    font-size: 1.7em;
  }

  h2 {
    font-size: 1.3em;
  }

  p {
    font-family: var(--font-base);
    font-size: 1.2em;
  }

  em {
    font-style: normal;
    font-weight: bold;
  }
}
