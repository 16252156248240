.container {
  max-width: 990px;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}