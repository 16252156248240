.container {
  font-size: 60px;
  position: relative;
  display: inline-block;
  z-index: 3;
  overflow: visible;

  &__underline {
    position: absolute;
    width: 105%;
    height: 3em;
    left: -1%;
    bottom: -110%;
    z-index: -1;

    &__fill {
      stroke: #1ad928;
      stroke-width: 130;
      fill: none;
      clip-path: url(#underline-clip-path);
    }
  }
}

