.wave__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  //min-height: 200px;
  max-width: 100vw;
  
}

.curve {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;



  &--1 {
    transform: translateX(-2%);
  }

  &--2 {
    transform: translateX(-98%);
  }

  &--3 {
    transform: translateX(-2%);
  }
}

.image_style {
  position: relative;
  bottom: 0;
  left: 0;
  width: 300%;
  transform: translateY(-100%);
}
