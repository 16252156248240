.container {
  font-size: 1em;
  &__content {
    &__title {
      &--text {
        font-size: 1.5em;
      }

      &--location {
        margin-bottom: 0.5em;
        &--icon {
          transform: translateY(10%);
        }
      }
    }

    &__details {
      padding-top: 0.5em;
      padding-left: 1em;
      font-size: 1.2em;
    }
  }

  li {
    list-style-type: disc;
    margin-left: 1.5em;
  }
}
