.circle {
  --color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  overflow: hidden;
  border:0.2em solid rgba(0, 0, 0, 0.2);
  -webkit-transition: color .5s;
  transition: color .5s;
  z-index: 1;
  box-shadow:  20px 20px 60px #26afb9,
             -20px -20px 60px #34edfb;
  color: var(--color);
 }
 
 .circle:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #59fffa;
  background: -webkit-linear-gradient(116deg, #59fffa 0%, #bc48ff 100%);
  background: linear-gradient(116deg, #59fffa 0%, #bc48ff 100%);
  min-height: 150px;
  min-width: 200px;
  width: 150%;
  height: 150%;
  border-radius: 50%;
 }
 
 .circle:hover {
  color: #fff;
 }
 
 .circle:before {
  top: 100%;
  left: 100%;
  -webkit-transition: all .7s;
  transition: all .7s;
 }
 
 .circle:hover:before {
  top: -30px;
  left: -30px;
 }